import React from 'react'
import Layout from '../../components/layout'
import { Link } from 'gatsby'
import { Button } from '@trexity/common-client/lib/components'
import MerchantSignUpButton from '../../components/merchantSignUpButton'

export default function NRML () {
  const [videoVisible, setVideoVisible] = React.useState(false)

  return (
    <Layout type='merchant'>
      <section className='well merchant-profile mt' style={{ maxWidth: 800, overflow: 'hidden' }}>
        <div>
          {!videoVisible ? (
            <div
              className="merchant-profile-yt responsive-video"
              style={{ backgroundImage: 'url("/img/merchant-profiles/youtube-nrml.jpg")' }}
              onClick={() => setVideoVisible(!videoVisible)}
            />
          ) : null}
          {videoVisible ? (
            <div className='responsive-video' style={{ borderRadius: 0 }}>
              <iframe
                src="https://www.youtube.com/embed/fhUuoyZvbts?rel=0&amp;modestbranding=1&amp;autoplay=1"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          ) : null}
        </div>
        <div className="inner">
          <span className='mice'>Joined Apr 15, 2020</span>
          <h2 className='mb-0'>NRML</h2>
          <p className='lede mb'>Q’s &amp; A’s with Manny, NRML’s E-Comm Manager </p>
          <div>
            <h4 className='mt'>What makes NRML special?</h4>
            <p className='mb-2'><strong>Manny:</strong> NRML is a local Canadian brand streetwear boutique. We specialize in streetwear, o sneakers, apparel. We get the community engaged in streetwear, sneakers, art, music, fashion, all that! We started in ’97 and this is actually our 25th anniversary. Yeah, we’ve been here for a bit.</p>

            <h4>What makes NRML different compared to other boutiques?</h4>
            <p className='mb-2'><strong>Manny:</strong> We have a lot of community engagement. We’ve had everybody lining up for releases, especially with the rise in this sneaker culture. We’re a really big standout, especially in Ottawa. NRML is the biggest streetwear boutique in the city and actually the biggest in Canada. Nobody has as many brands or the volume of inventory that we have.</p>

            <div className='mb-2'>
              <blockquote className='quote mb-2'>
                With Trexity, customers get the opportunity to shop online and get our products delivered to them the same day, or faster even.
              </blockquote>
            </div>

            <h4>Tell me about how the business has grown, where you started in Ottawa, and how you’ve grown over the years.</h4>
            <p className='mb-2'><strong>Manny:</strong> As we acquired bigger brands and more and more people embraced streetwear and fashion, we grew quickly. We moved around a bit and experimented with pop-ups until we launched and settled into our large store here on Rideau St. We now have more than enough space. NRML is always expanding, especially with e-commerce getting bigger and bigger. The ceiling is only as high as you want to put it…and we’ve set it pretty high for NRML</p>

            <h4>As NRML has grown, how has home delivery helped?</h4>
            <p><strong>Manny:</strong> Yeah. I’d say pre-pandemic, the idea of local delivery was pretty new to all of us, people were used to just stopping by the store and buying things physically, but then online shopping online blew up and having it delivered to your door the same day, well, that changes the game. </p>
            <p className='mb-2'>This means I don’t have to step out of my house in minus 30 weather to shop, right? And now with the pandemic going on, it’s even more convenient because customers can stay safe at home and still shop for their favourite brands at their favourite store, and still get it the same day. It’s a much easier customer experience for them. This customer service, more than anything, is what we want to offer.</p>

            <h4>How is Trexity helping NRML reach customers?</h4>
            <p className='mb-2'><strong>Manny:</strong> With Trexity, customers get the opportunity to shop online and get our products delivered to them the same day, or faster even. The customer service with Trexity has been great. It’s easy for customers. It’s easy for us.</p>

            <h4>Do you think with home delivery, NRML is reaching new audiences?</h4>
            <p><strong>Manny:</strong> Yeah, there is growth for sure. When we have customers come by the store, for instance on Boxing Day, we had a huge line of customers outside and it’s easy to just let them know, “Look, you don’t have to be outside in the cold. You don’t have to be exposed. If you’re worried about your own safety, but you still want to be able to shop and have a seamless shopping experience, you can always just go on to our website, and choose the Trexity same day option and they’ll just deliver it to your door.”</p>
            <p className='mb-2'>So it’s been a huge improvement on the customer service side of things…people always get surprised and I’m like, “It’s possible.”</p>

            <div className='row align-center space-between'>
              <div className='col mb d-mb-0'>
                <a href="https://nrml.ca/" target="_blank" rel="noreferrer">
                  <img src="/img/merchants/nrml.jpg" alt="Overflow Beer logo" style={{ maxWidth: 120 }} />
                </a>
              </div>

              <div className='col'>
                <a href='https://nrml.ca/' className='cta-link' target="_blank" rel="noreferrer">Visit NRML <i className="material-icons">keyboard_arrow_right</i></a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row justify-center'>
          <div className='col text-center'>
            <div className="inner">
              <h2>Keep the local community</h2>
              <p className='mb'>When people do what they love, they do it better.</p>
              <MerchantSignUpButton />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
